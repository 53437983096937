const authReducer = (state, action) => {

    switch(action.type) {
        case "LOGIN":
            localStorage.setItem("client_token", JSON.stringify(action.payload.token));
            localStorage.setItem("client_id", JSON.stringify(action.payload.userId));
            return {
                ...state,
                isAuth: true,
                token: action.payload.token,
                email: action.payload.email
            }
        case "LOGOUT":
            localStorage.clear();
            return {
                ...state,
                isAuth: false,
                token: null
            }
        default:
            return state
    }
};

export default authReducer;
